import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "./RichtextHelper/CustomText";
import { isMobile, isTablet } from "../../hooks/checkMobile"

export const Benefit = ({ benefits, title }) => {
  if (!benefits || !title) return null;
  return (
    <WrapperSection>
      <Content>
        <YourBenefit bgc={color.primary.blue05}>
          <BenTitle
            tag={isMobile ? "h3" : isTablet ? "h4" : "h3"}
            color={color.primary.blue}
            capital="uppercase"
            weight={typography.weight.regular1}
          >
            <RichText render={title} htmlSerializer={htmlSerializer} />
          </BenTitle>
        </YourBenefit>
        {benefits?.map((item, i) => (
          <Div
            key={i}
            bgc={
              i === 0
                ? `rgba(255, 227, 34, 0.1)`
                : i === 1
                ? `rgba(92, 198, 143, .1)`
                : `rgba(76, 143, 204, .1)`
            }
          >
            <Title
              tag={isMobile ? "h4" : "h5"}
              capital="uppercase"
              weight={typography.weight.regular}
              color={color.netraul.black100}
            >
              {item.description.text}
            </Title>
            <Description
              color={color.netraul.black90}
              font={typography.type.primary}
              lineHeight={26}
            >
              {item.content.text}
            </Description>
          </Div>
        ))}
      </Content>
    </WrapperSection>
  );
};

Benefit.propTypes = {
  title: PropTypes.string,
};
const Div = styled.div`
  background-color: ${props => props.bgc};
  width: 25%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 77px 44px;
  @media (max-width: 1290px) and (min-width: 1001px) {
    padding: 73px 20px;
  }
  @media (max-width: 1000px) and (min-width: 801px) {
    padding: 73px 10px;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: 50px 48px;
  }
`;
const BenTitle = styled(Text)`
  span {
    font-size: 90px;
    line-height: 90px;
    color: ${color.primary.blue};
    text-transform: uppercase;
    font-weight: ${typography.weight.regular1};
    font-family: ${typography.type.code};
    word-break: normal;
    @media (max-width: 1000px) and (min-width: 651px) {
      line-height: 62px;
    }
    @media (max-width: 650px) {
      font-size: 62px;
      line-height: 62px;
    }
  }
`
const YourBenefit = styled.div`
  font-weight: ${typography.weight.regular1};
  text-align: left;
  background-color: ${props => props.bgc};
  max-width: calc(100vw / 3);
  width: 25%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 55px 64px;
  @media (max-width: 1400px) {
    padding: 55px 40px;
  }
  @media (max-width: 1250px) {
    padding: 55px 20px;
  }
  @media (max-width: 1110px) {
    padding: 55px 10px;
  }
  @media (max-width: 1000px) {
    padding: 55px 20px;
  }
  @media (max-width: 800px) {
    justify-content: left;
    width: 100%;
    max-width: 100%;
    padding: 60px 48px;
  }
  @media (max-width: 650px) {
    font-size: 62px;
    line-height: 62px;
    text-align: left;
    font-size: ${typography.size.l4}px;
    padding: 40px 48px;
  }
`;

const Title = styled(Text)`
  /* padding-right: 33px; */
  text-align: left;
  font-weight: ${typography.weight.regular};
  @media (max-width: 1400px) {
    padding-right: 0;
  }
  @media (max-width: 650px) {
    padding-right: 55px;
    margin: 0;
    font-size: 36px !important;
    line-height: 36px;
  }
`;
const Description = styled(Text)`
  margin: 0 auto;
  margin-top: 20px;
  text-align: left;
  font-weight: normal;
  @media (max-width: 650px) {
    margin: 0;
    margin-top: 20px;
  }
`;
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  padding: 5px;
  box-sizing: border-box;
  @media (max-width: 650px) {
    padding: 0;
  }
`;
const Content = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
