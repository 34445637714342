import React, {useRef, useEffect, useState, useCallback} from "react";
import styled, { keyframes } from "styled-components";
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "./RichtextHelper/CustomText";
import estate from "../../images/estate.svg";
import { isMobile } from "../../hooks/checkMobile";
// import { navigate } from "gatsby";
import {useOnScreen} from "../../hooks/useOnScreen";
import Modal from "../../components/Modal/modal";
import { SendMessageForm } from "./modal/SendMessageForm"
import { redirectPage } from "../../hooks/redirect";

const CircleWrapper = ({onClick}) => {
  return (
    <Wrapper>
      <Round onClick={onClick}>
        <Wave />
        <Circle>
          <Icon src={estate} />
          <TextExtend font={typography.type.primary} color={color.netraul.black100}>Partner with<br/>us</TextExtend>
        </Circle>
      </Round>
    </Wrapper>
  )
}
export const Estate = ({ data, setNumberActive }) => {
  const { items, primary } = data;
  const [openContact, setOpenContact] = useState(false);
  const ref = useRef(null)
  const onScreen = useOnScreen(ref, "-400px")
  useEffect(() => {
    if(onScreen ) setNumberActive(5)
  }, [onScreen, setNumberActive]);
  const handleOpenModal = useCallback(() => {
    setOpenContact(!openContact)
  }, [openContact, setOpenContact])
  if (!items || !primary) return null;
  return (
    <WrapperSection id="partner-5" ref={ref}>
      <WrapperModal>
            <Modal open={openContact} handleOpenModal={handleOpenModal}>
              <SendMessageForm
                handleOpenModal={handleOpenModal}
                url={process.env.GATSBY_MAILCHIMP_URL}
              />
            </Modal>
          </WrapperModal>
      <WrapperContent>
        <Head>
          <Left>
            <WrapperTitle>
              <Title
                capital="uppercase"
                tag="h3"
                weight={typography.weight.regular1}
                color={color.netraul.black80}
              >
                <RichText
                  render={primary?.title?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Title>
            </WrapperTitle>
            <WrapperDescription>
              <Description
                weight={typography.weight.regular}
                font={typography.type.primary}
                lineHeight={26}
                color={color.netraul.black80}
              >
                <RichText
                  render={primary?.description?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Description>
            </WrapperDescription>
          </Left>
          <Right>
            <CircleWrapper onClick={() => redirectPage({
              link_type: primary?.button_link?.link_type,
              type: primary?.button_link?.type,
              url: primary?.button_link?.url
            })}/>
          </Right>
        </Head>
        <Content>
          <Text
            tag={isMobile ? "h4" : "h5"}
            capital="uppercase"
            weight={typography.weight.regular}
            color={color.netraul.black100}
          >
            {primary?.sub_title?.text}
          </Text>
          <Benefit>
            {items?.map((item, i) => (
              <Item key={i}>
                <Icon_2 src={item.icon.url} />
                <Text
                  font={typography.type.primary}
                  lineHeight={26}
                  color={color.netraul.black80}
                >
                  {item.interest.text}
                </Text>
              </Item>
            ))}
          </Benefit>
          <Bottom>
            <Btn type="tertiary" onClick={() => redirectPage({
              link_type: primary?.button_link?.link_type,
              type: primary?.button_link?.type,
              url: primary?.button_link?.url
            })}>{primary?.button_lable?.text}</Btn>
          </Bottom>
        </Content>
      </WrapperContent>
    </WrapperSection>
  );
};

Estate.propTypes = {
  title: PropTypes.string,
};
const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`
const Btn = styled(Button)`
  border-radius: 40px;
  font-weight: 700;
  line-height: 26px;
`
const WrapperTitle = styled.div`
  @media (max-width: 376px) {
    width: 50%;
  }
`
const WrapperDescription = styled.div`
  margin-top: 23px;
  margin-bottom: 20px;
  @media (max-width: 650px) {
    margin-top: 30px;
  }
`
const TextExtend = styled(Text)`
  font-size: 20px;
  line-height: 28px;
  font-weight: ${typography.weight.bold};
  @media (max-width: 650px) {
    font-size: 18px;
    font-weight: ${typography.weight.regular1};
  }
`
const Bottom = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: block;
    text-align: center;
    margin-top: 36px;
  }
`
const Head = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 850px) and (min-width: 651px) {
    flex-direction: column;
  }
`
const Left = styled.div`
  width: 55%;
  @media (max-width: 1000px) {
    width: 100%;
    /* margin-bottom: 40px; */
  }
`
const Right = styled.div`
  width: 45%;
  margin-right: 58px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1100px) and (min-width: 1000px) {
    margin-right: 0;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`
const Content = styled.div`
  @media (max-width: 650px) {
  /* margin-top: 60px; */
  }
`
const Benefit = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 57px;
  @media (max-width: 850px) {
    flex-wrap: wrap;
  }
  @media (max-width: 650px) {
    margin-top: 33px;
  }

`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 60px;
  width: 25%;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    padding-right: 10px;
  }
  @media (max-width: 850px) {
    padding-right: 20px;
    width: 50%;
    margin-bottom: 50px;
  }
  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 24px;
    flex-direction: row;
    align-items: flex-start;
  }
`
const Wrapper = styled.div`
  max-width: 372px;
  max-height: 372px;
  width: 100%;
  height: 100%;
  @media (max-width: 650px) {
    
    max-width: 216px;
    max-height: 216px;
  }
`

const animate = keyframes`
  10% {
    opacity: 0.2;
  }
  50% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}`
const Wave = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: ${color.secondary.yellowcream};
  border-radius: 50%;
  border-radius: 50%;
  pointer-events: none;
  transition: 1s;
`
const Round = styled.div`
  &:hover {
    div:first-child {
      animation:${animate} 1s 0.5s linear infinite;
    }
  }
  cursor: pointer;
  width: 100%;
  max-height: 372px;
  height: calc(100vw * 45 / 100);
  border-radius: 50%;
  background-color: rgba(181, 151, 62, 0.1);
  position: relative;
  @media (max-width: 650px) {
    height: 216px;
    max-height: 216px;
  }
`
const Circle = styled.div`
  position: absolute;
  text-align: center;
  max-width: 258px;
  max-height: 258px;
  height: 100%;
  width: 100%;
  bottom: 0;
  top: 0;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: ${color.secondary.yellowcream};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    max-width: 176px;
    max-height: 176px;
  }
`
const Icon = styled.img`
  width: 100%;
  height: 100%;
  max-width: 64px;
  max-height: 64px;
  margin-bottom: 29px;
  @media (max-width: 650px) {
    max-width: 28px;
    max-height: 28px;
  }
`
const Icon_2 = styled.img`
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
  @media (max-width: 650px) {
    margin-bottom: 15px;
    padding-right: 22px;
  }
`
const WrapperContent = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 48px;
  padding-top: 112px;
  @media (max-width: 1000px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 650px) {
    padding-top: 67px;
  }
`
const Title = styled(Text)`
  @media (max-width: 650px) {
    margin: 0;
    letter-spacing: -0.02em;
  }
`;
const Description = styled(Text)`
  @media (max-width: 650px) {
    margin: 0;
  }
`;
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  background-color: ${color.primary.white};
  box-sizing: border-box;
  padding-bottom: 80px;
  @media (max-width: 1000px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 650px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 90px;
  }
`;