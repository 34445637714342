import React, {useState} from 'react'
import styled from "styled-components";
import { Banner } from "./Banner"
import { Become } from "./Become"
import { Benefit } from "./Benefit"
import { Estate } from "./Estate"
import { Invitation } from "./Invitation"
import scrollTo from "gatsby-plugin-smoothscroll";

export const PartnerContainer = ({ data, ref }) => {
  const [numberActive, setNumberActive] = useState("")
  const onScroll = (id) => {
    // console.log(id)
    scrollTo("#partner-"+id);
  }
  return (
    <WrapperLayout>
      <Banner
        banner={data?.banner?.url}
        title={data?.banner_title?.text}
        description={data?.banner_description?.text}
      />
      <Benefit benefits={data?.benefits} title={data?.benefit_title?.raw} />
      <Estate
        data={data?.body1[0] === undefined ? {} : data?.body1[0]}
        setNumberActive={setNumberActive}
      />
      <Invitation data={data?.body2[0] === undefined ? {} : data?.body2[0]} />
      <Become
        title={data?.about_title?.raw}
        data={data?.body}
        onScroll={onScroll}
        setNumberActive={setNumberActive}
        numberActive={numberActive}
      />
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin: 0 auto;
  position: relative;
`;
