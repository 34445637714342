import React, { createRef, useRef } from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import educator from "../../images/educator.svg"
import educator_active from "../../images/educator_active.svg"
import investor from "../../images/investor.svg"
import investor_active from "../../images/investor_active.svg"
import goverment from "../../images/goverment.svg"
import goverment_active from "../../images/goverment_active.svg"
import service from "../../images/service.svg"
import service_active from "../../images/service_active.svg"
import ecosystem from "../../images/ecosystem.svg"
import ecosystem_active from "../../images/ecosystem_active.svg"
import partner from "../../images/partner.svg"
import partner_active from "../../images/partner_active.svg"
import { RichText } from "prismic-reactjs";
import htmlSerializer from "./RichtextHelper/CustomText";
import { DetailBecome } from "./DetailBecome";
import { isMobile } from "../../hooks/checkMobile"
const becomes = [
  {
    icon: investor,
    icon_active: investor_active,
    title: "Investors"
  },
  {
    icon: educator,
    icon_active: educator_active,
    title: "Educators"
  },
  {
    icon: service,
    icon_active: service_active,
    title: "Service providers"
  },
  {
    icon: goverment,
    icon_active: goverment_active,
    title: "Goverments"
  },
  {
    icon: ecosystem,
    icon_active: ecosystem_active,
    title: "Eccosystem Partners"
  },
  {
    icon: partner,
    icon_active: partner_active,
    title: "Real-Estate Partners"
  },
];

export const Become = ({ data, title, onScroll, setNumberActive, numberActive}) => {
  const elRefs = useRef([]);
  if (elRefs.current.length !== data.length) {
    elRefs.current = Array(data.length).fill().map((_, i) => elRefs.current[i] || createRef());
  }

  // const [menuActived, setActive] = useState(-1)
  const menu = useRef(null)
  let pos = { left: 0, x: 0 };
  // useEffect(() => {
  //     setActive(numberActive)
  //     // if(numberActive > menuActived) scrollNext(); else scrollBack();
  // }, [numberActive]);
  // const scrollBack = () => {
  //   menu.current.scrollLeft += -250;
  // }
  // const scrollNext = () => {
  //   menu.current.scrollLeft += 250;
  // }
  const mouseDownHandler = (e) => {
      menu.current.style.userSelect = 'none';
      pos = {
          left: menu.current.scrollLeft,
          x: e.clientX,
      };
      menu.current.addEventListener('mousemove', mouseMoveHandler);
      menu.current.addEventListener('mouseup', mouseUpHandler);
      menu.current.addEventListener('mouseleave', mouseUpHandler);
  };
  const mouseUpHandler = () => {
      menu.current.removeEventListener('mousemove', mouseMoveHandler);
  };
  const mouseMoveHandler = (e) => {
      const dx = e.clientX - pos.x;
      menu.current.scrollLeft = pos.left - dx;
  };
  const onPressItem = (i) => {
      onScroll(i)
      // console.log(i)
      setNumberActive(i)
  } 

  return (<>
    <BecomeTitle capital="uppercase" tag="h3" color={color.primary.blue} weight={typography.weight.regular1}>
      <RichText render={title} htmlSerializer={htmlSerializer} />
    </BecomeTitle>
    <Content ref={menu} onMouseDown={(e) => mouseDownHandler(e)}>
      {
        becomes?.map((item, i) => <>
          <Div icon_active={item?.icon_active} onClick={() => onPressItem(i)} numberActive={numberActive === i}>
            <Icon icon_default={item?.icon} />
            <Title font="Oxygen" size={isMobile ? 16 : 14} lineHeight={isMobile ? 26 : 22} weight={typography.weight.bold}>{item.title}</Title>
          </Div>
        </>)
      }
    </Content>
    <WrapperSection>
      {data?.map((item, i) => (
        <DetailBecome key={i} data={item} i={i} reff={elRefs.current[i]} numberActive={numberActive} setNumberActive={setNumberActive}/>
      ))}
    </WrapperSection></>
  );
};

Become.propTypes = {
  title: PropTypes.string,
};

// const Wrapper = styled.div`
//   max-width: 1500px;
//   margin: auto;
//   box-sizing: border-box;
// `;
// const Educators =styled(Educator) `
//   width: 10px;
//   height: 10px;
// `
// const Collapse = styled.div`
// display: none;
//   @media (max-width: 1000px) {
//     display: block;
//   }
  
// `
const Div = styled.div`
/* margin-top: 10px; */
  letter-spacing: -0.015em;
  box-sizing: border-box;
  width: 250px;
  min-width: 250px;
  height: 80px;
  cursor: pointer;
  border: 1px solid ${color.primary.blue20};
  padding: 25px 34px 23px 34px;
  align-items: center;
  width: calc(100% / 6);
  display: flex;
  justify-content: center;
  flex-direction: row;
  &>div:first-child {
    ${props => props.numberActive && `
        transition: 0.5s;
        background-image: url(${props.icon_active});
    `}
  }
  &:hover {
    &>div:first-child {
      transition: 0.5s;
      background-image: url(${props => props.icon_active});
    }
    &>span {
      transition: 0.5s;
      color: ${color.netraul.black100}
    }
  }
`;
const Icon = styled.div`
  padding-right: 12px;
  max-width: 32px;
  max-height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.5s;
  background-image: url(${props => props.icon_default});
  width: 100%;
  height: 100%;
`
const Title = styled(Text)`
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
  @media (max-width: 650px) {
    text-align: left;
    margin: 0;
  }
`;
const BecomeTitle = styled(Text)`
  margin-top: 145px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  text-align: center;
  @media (max-width: 650px) {
    margin-top: 70px;
  };
  /* span {
    text-transform: uppercase;
    color: ${color.primary.blue};
    font-weight: ${typography.weight.regular1};
    font
  } */
`
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 50px;
  @media (max-width: 1000px) {
  }
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
const Content = styled.div`
scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  top: 90px;
  background-color: white;
  height: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
`;
