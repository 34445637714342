import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Text } from "../../../components/Text/Text";
import { color, typography } from "../../../shared/style";
import close from "../../../images/close_icon.svg";
import { Button } from "../../../components/Button/Button";
import { Link } from "../../../components/Link/Link";

export const SendMessageForm = ({ handleOpenModal, url }) => {
  const [checked, setChecked] = useState(false);
  const [errors, setError] = useState({ nameError: null, mailError: null });
  const [dataForm, setData] = useState({ name: "", email: "" });
  const checkbox = useRef();
  const changeChecked = (e) => {
    checkbox.current.checked = !checked;
    setChecked(!checked);
  };
  const onChangeData = (e) => {
    setData({ ...dataForm, [e.target.name]: e.target.value });
  };

  const isEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onSubmit = (e) => {
    if (dataForm.name === "" && dataForm.email === "") {
      setError({
        ...errors,
        nameError: "name is require!",
        mailError: "email is require!",
      });
      return e.preventDefault();
    } else if (dataForm.name === "" && dataForm.email !== "") {
      if (!isEmail(dataForm.email)) {
        setError({
          ...errors,
          mailError: "This field must be email",
          nameError: "name is require!",
        });
      } else {
        setError({
          ...errors,
          mailError: null,
          nameError: "name is require!",
        });
      }
      return e.preventDefault();
    } else if (dataForm.email === "" && dataForm.name !== "") {
      setError({ ...errors, mailError: "email is require!", nameError: null });
      return e.preventDefault();
    } else {
      if (!isEmail(dataForm.email)) {
        setError({
          ...errors,
          mailError: "This field must be email",
          nameError: null,
        });
        return e.preventDefault();
      } else {
        setError({ ...errors, mailError: null, nameError: null });
        return true;
      }
    }
  };
  const onClose = () => {
    setError({ ...errors, mailError: null, nameError: null });
    handleOpenModal();
  };
  return (
    <WrapperSection>
      <Content>
        <Wrapper>
          <Title
            tag="h5"
            weight={typography.weight.regular1}
            font={typography.type.code}
            capital="uppercase"
            color={color.netraul.black100}
          >
            Become our partner
          </Title>
          <CloseIcon onClick={onClose} src={close}></CloseIcon>
        </Wrapper>
        <Description font="Oxygen" align="left" color={color.netraul.black100}>
          This form collects your name and email so that we can correspond with
          you.{" "}
        </Description>
        <Text font="Oxygen" align="left" color={color.netraul.black100}>
          View our <Link to="">Privacy Policy</Link> for more information.
        </Text>
        <Form
          method="post"
          target="_blank"
          onSubmit={onSubmit}
          action={url}
          // url="https://gmail.us5.list-manage.com/subscribe/post?u=ffb468a23f2adce54d80b68bf&amp;id=80fa29e167"
        >
          <Input
            placeholder="Your name"
            name="name"
            onChange={onChangeData}
          ></Input>
          {errors.nameError && (
            <LabelError
              font={typography.type.primary}
              size={typography.size.s1}
            >
              {errors.nameError}
            </LabelError>
          )}
          <Input
            placeholder="Your email"
            name="email"
            onChange={onChangeData}
          ></Input>
          {errors.mailError && (
            <LabelError
              font={typography.type.primary}
              size={typography.size.s1}
            >
              {errors.mailError}
            </LabelError>
          )}
          <WrapperCheckbox>
            <Checkbox type="checkbox" id="checkbox1" ref={checkbox}></Checkbox>
            <LabelCheckbox htmlFor="checkbox1" onClick={changeChecked}>
              <Label
                weight={typography.weight.regular}
                font={typography.type.primary}
                color={color.netraul.black100}
                align="left"
              >
                I consent to having draperstartuphouse.com collect my name and
                email.
              </Label>
            </LabelCheckbox>
          </WrapperCheckbox>
          <WrapperButton>
            <ButtonSubmit type="primary" disable={!checked}>
              Submit
            </ButtonSubmit>
          </WrapperButton>
        </Form>
      </Content>
    </WrapperSection>
  );
};

const Description = styled(Text)`
  padding-top: 15px;
  padding-bottom: 4px;
`;
const WrapperSection = styled.div`
  width: 511px;
  height: fit-content;
  background-color: ${color.primary.white};
  padding: 30px 31px;
  position: absolute;
  z-index: 999;
  @media (max-width: 1000px) and (min-width: 650px) {
    width: auto;
    margin: 0 20px;
  }
  @media (max-width: 649px) {
    position: fixed;
    width: auto;
    margin: 0 20px;
  }
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;
const Title = styled(Text)``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;
const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;
const Input = styled.input`
  margin-bottom: 20px;
  border-radius: 50px;
  background: ${color.primary.blue05};
  outline: none;
  border: none;
  height: 55px;
  padding-left: 20px;
  font-weight: 400;
  font-size: 16px;
  font-family: ${typography.type.primary};
  color: ${color.netraul.black50};
  line-height: 26px;
`;

const WrapperCheckbox = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
`;

const LabelCheckbox = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  &:before {
    content: "";
    width: 24px;
    height: 24px;
    min-width: 24px;
    -webkit-appearance: none;
    background: rgba(23, 41, 64, 0.1);
    border-radius: 5px;
    border: none;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
  }
`;
const Checkbox = styled.input`
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  &:checked + ${LabelCheckbox}:after {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 8px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const Label = styled(Text)`
  margin-left: 12px;
  text-align: left;
`;

const ButtonSubmit = styled(Button)`
  border-radius: 40px;
  font-family: ${typography.type.primary};
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  padding: 20px 45px;
  line-height: 26px;
  width: fit-content;
`;

const LabelError = styled(Text)`
  color: #ad0707;
  margin-top: -15px;
  margin-bottom: 15px;
  padding-left: 20px;
  text-align: left;
`;
