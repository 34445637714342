import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import banner from "../../images/banner.png";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";

export const Banner = ({ banner, title, description }) => {
  return (
    <WrapperSection banner={banner}>
      <Content>
        <Title
          font={typography.type.code}
          tag="h3"
          color={color.primary.white}
          capital="uppercase"
          align="center"
        >
          {title}
        </Title>
        <Description
          font={typography.type.primary}
          color={color.primary.white}
          lineHeight={26}
          weight={typography.weight.bold}
          align="center"
        >
          {description}
        </Description>
      </Content>
    </WrapperSection>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
};

const Title = styled(Text)`
  padding: 0 20px 10px 20px;
  margin: 0 auto;
  font-weight: normal;
  line-height: 90px;
  @media (max-width: 1000px) and (min-width: 651px) {
    line-height: 62px;
  }
  @media (max-width: 650px) {
    padding: 0 20px 0px 20px;
    font-weight: 500;
    font-size: 62px;
    line-height: 62px;
  }
`;
const Description = styled(Text)`
  margin-top: 12px; 
  max-width: 403px;
  margin: 0 auto;
  @media (max-width: 650px) {
    padding: 16px 20px;
  }
`;
const WrapperSection = styled.div`
  /* height: calc(100vh + 90px); */
  min-height: 700px;
  padding-top: 90px;
  box-sizing: border-box;
  display: flex;
  background-image: url(${(props) => (props.banner ? props?.banner : banner)});
  background-size: cover;
  background-position: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    min-height: 520px;
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  width: 60%;
  flex-direction: column;
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
