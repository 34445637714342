import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import { Button } from "../../components/Button/Button";
import Modal from "../../components/Modal/modal";
import { SendMessageForm } from "./modal/SendMessageForm"
import { redirectPage } from "../../hooks/redirect";
export const Invitation = ({ data }) => {
  const { primary } = data;
  const [openContact, setOpenContact] = useState(false);
  const handleOpenModal = useCallback(() => {
    setOpenContact(!openContact)
  }, [openContact, setOpenContact])
  if (!primary) return null;
  return (
    <WrapperSection>
      <WrapperContent>
        <Image src={primary?.image?.url} />
        <WrapperTitle>
          <Title
            tag="h4"
            capital="uppercase"
            weight={typography.weight.regular}
            color={color.primary.white}
          >
            {primary?.title?.text}
          </Title>
        </WrapperTitle>
        <WrapperDesctiption>
          <Description
            color={color.primary.blue20}
            weight={typography.weight.regular}
            lineHeight={26}
            font={typography.type.primary}
          >
            {primary?.description?.text}
          </Description>
        </WrapperDesctiption>
        <WrapperButton>
          <Btn type="tertiary" onClick={() => redirectPage({
            link_type: primary?.button_link?.link_type,
            type: primary?.button_link?.type,
            url: primary?.button_link?.url
          })}>
            {primary?.button_label?.text}
          </Btn>
          <WrapperModal>
            <Modal open={openContact} handleOpenModal={handleOpenModal}>
              <SendMessageForm
                handleOpenModal={handleOpenModal}
                url={process.env.GATSBY_MAILCHIMP_URL}
              />
            </Modal>
          </WrapperModal>
        </WrapperButton>
      </WrapperContent>
    </WrapperSection>
  );
};

Invitation.propTypes = {
  title: PropTypes.string,
};
const WrapperTitle = styled.div`
  text-align: center;
  margin-top: 60px;
  width: 60%;
  @media (max-width: 650px) {
    margin-top: 40px;
    width: 100%;
  }
`
const WrapperDesctiption = styled.div`
  text-align: center;
  margin-top: 24px;
  margin-bottom: 32px;
  width: 60%;
  @media (max-width: 650px) {
    margin-top: 18px;
    margin-bottom: 40px;
    width: 100%;
  }
`
const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`
const WrapperButton = styled.div`
  text-align: center;
`
const Btn = styled(Button)`
  border-radius: 40px;
`
const Image = styled.img``
const Title = styled(Text)`
  @media (max-width: 650px) {
    margin: 0;
  }
`;
const Description = styled(Text)`
@media (max-width: 650px) {
    margin: 0;
  }
`;
const WrapperContent = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 650px) {
    padding-bottom: 80px;
  }
`
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #ffffff 25%, #204370 25%);
  width: 100%;
  @media (max-width: 1000px) {
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
    background: linear-gradient(to bottom, #ffffff 15%, #204370 15%);
  }
`;