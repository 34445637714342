import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { Link } from "../../components/Link/Link";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "./RichtextHelper/CustomText";
import Modal from "../../components/Modal/modal";
import { ModalContact } from "./modal/ModalContact"
import { useOnScreen } from "../../hooks/useOnScreen"
export const DetailBecome = ({ data, i, reff, setNumberActive, numberActive }) => {
  const onScreen = useOnScreen(reff, "-400px")
  useEffect(() => {
    if (onScreen) setNumberActive(i);
    //eslint-disable-next-line
  }, [onScreen, i]);
  const { items, primary } = data;
  const [openContact, setOpenContact] = useState(false);
  const handleOpenModal = useCallback(() => {
    setOpenContact(!openContact)
  }, [openContact, setOpenContact])
  if (!items || !primary) return null;
  return (
    <WrapperContent
      isLeft={primary?.image_position === "left"}
      id={"partner-" + i}
      ref={reff}
    >
      <WrapperImage>
        <Image
          isLeft={primary.image_position === "left"}
          color={primary.shadow_color}
          src={primary?.image?.url}
        />
      </WrapperImage>
      <Content isLeft={primary.image_position === "left"}>
        <WrapperTitle>
          <Title
            tag="h4"
            capital="uppercase"
            color={color.netraul.black100}
            weight={typography.weight.regular}
          >
            <RichText
              render={primary?.title.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
        </WrapperTitle>
        <Description
          font="Oxygen"
          lineHeight={26}
          color={color.netraul.black80}
        >
          <RichText
            render={primary?.description.raw}
            htmlSerializer={htmlSerializer}
          />
        </Description>
        <Partners>
          {primary?.partner_logos?.url ?
            <ItemPartnerLogo key={i}>
              <Img src={primary?.partner_logos?.url} />
            </ItemPartnerLogo>
            :
            items?.map((item, i) => (
              <ItemPartner key={i}>
                <Img src={item.partner.document.data.logo.url} />
              </ItemPartner>
            ))
          }
        </Partners>
        <Btn onClick={() => handleOpenModal()}>{primary?.link_label?.text}</Btn>
        <WrapperModal>
          <Modal open={openContact} handleOpenModal={handleOpenModal}>
            <ModalContact
              handleOpenModal={handleOpenModal}
              email={primary?.email?.text}
            />
          </Modal>
        </WrapperModal>
      </Content>
    </WrapperContent>
  );
};

DetailBecome.propTypes = {
  title: PropTypes.string,
};

const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`
const Btn = styled(Link)`
  text-underline-offset: 5px;
  display: flex;
  justify-content: left;
  margin-top: 19px;
  color: ${color.primary.blue};
  text-decoration: underline;
  font-weight: bold;
`;
const Title = styled(Text)`
  span{
    color: ${color.netraul.black100};
  }
  padding-right: 88px;
  @media (max-width: 650px) {
    padding-right: 0;
  }
`;
const Partners = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* @media (max-width: 650px) { */
  justify-content: flex-start;
  /* } */
`;
const WrapperTitle = styled.div`
  margin-bottom: 32px;
  @media (max-width: 650px) {
    margin-bottom: 24px;
  }
`;
const ItemPartner = styled.div`
  width: 100%;
  height: 100%;
  max-width: 90px;
  max-height: 41px;
  margin-bottom: 21px;
  @media (min-width: 651px) {
    margin-right: 10px;
  }
`;
const ItemPartnerLogo = styled.div`
  width: 100%;
  height: 100%;
  /* max-width: 90px; */
  max-height: 41px;
  margin-bottom: 21px;
  @media (min-width: 651px) {
    margin-right: 10px;
  }
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
`;
const WrapperImage = styled.div`
  line-height: 0;
  width: 50%;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 54px;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding: 0;
    padding-bottom: 26px;
    margin-bottom: 40px;
  }
`;
const Content = styled.div`
  width: 50%;
  box-sizing: border-box;
  ${(props) =>
    props.isLeft
      ? `
    padding-left: 75px;
    padding-right: 130px;
  `
      : `
    padding-left: 130px;
    padding-right: 75px;
  `}
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1000px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding: 0;
  }
`;
const Description = styled(Text)``;
const Image = styled.img`
  width: 100%;
  height: 438px;
  object-fit: cover;
  ${(props) =>
    props.isLeft
      ? `box-shadow: -48px 54px 1px ${props.color}`
      : `box-shadow: 48px 54px 1px ${props.color}`};
  @media (max-width: 1000px) {
    ${(props) =>
    props.isLeft
      ? `box-shadow: -40px 46px 1px ${props.color}`
      : `box-shadow: 40px 46px 1px ${props.color}`};
  }
  @media (max-width: 650px) {
    ${(props) =>
    props.isLeft
      ? `box-shadow: -20px 26px 1px ${props.color}`
      : `box-shadow: 20px 26px 1px ${props.color}`};
    max-height: 214px;
  }
`;
const WrapperContent = styled.div`
  max-height: 1500px;
  margin: auto;
  box-sizing: border-box;
  margin-top: -50px;
  padding-top: 200px;
  display: flex;
  flex-direction: ${(props) => (props.isLeft ? "row" : "row-reverse")};
  &:first-child {
    padding-top: 150px;
  }
  @media (max-width: 650px) {
    margin-top: -250px;
    &:first-child {
      margin-top: -150px;
      padding-top: 220px;
    }
    flex-direction: column;
    padding-top: 250px;
    margin-bottom: 91px;
  }
`;
