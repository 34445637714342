import React from "react"
import styled from "styled-components"
import { Text } from "../../../components/Text/Text"
import mail from "../../../images/mail.svg"
import outlook from "../../../images/outlook.svg"
import yahoo from "../../../images/yahoo.svg"
import default_mail from "../../../images/defaultmail.svg"
import link_icon from "../../../images/Link.svg"
import close from "../../../images/close_icon.svg"
import { color, typography } from "../../../shared/style"

export const ModalContact = ({ handleOpenModal, email }) => {
  return (
    <WrapperDetail>
      <WrapperTitle>
        <Title
          tag="h5"
          size={typography.size.l1}
          font={typography.type.code}
          capital="uppercase"
          color={color.netraul.black100}
          weight={typography.weight.regular1}
        >Compose new email with</Title>
        <CloseIcon onClick={handleOpenModal} src={close}></CloseIcon>
      </WrapperTitle>
      <Item href={`mailto:${email}`}>
        <Icon src={mail}></Icon>
        <Label
          size={typography.size.s3}
          font={typography.type.primary}
          color="#000000"
        ><Text
          weight={typography.weight.bold}
          font={typography.type.primary}
          color={color.netraul.black100}
        >Gmail</Text> in browser</Label>
      </Item>
      <Item href={`mailto:${email}`}>
        <Icon src={outlook}></Icon>
        <Label
          size={typography.size.s3}
          font={typography.type.primary}
          color="#000000"
        ><Text
          weight={typography.weight.bold}
          font={typography.type.primary}
          color={color.netraul.black100}
        >Outlook</Text> in browser</Label>
      </Item>
      <Item href={`mailto:${email}`}>
        <Icon src={yahoo}></Icon>
        <Label
          size={typography.size.s3}
          font={typography.type.primary}
          color="#000000"
        ><Text
          weight={typography.weight.bold}
          font={typography.type.primary}
          color={color.netraul.black100}
        >Yahoo</Text> in browser</Label>
      </Item>
      <Item href={`mailto:${email}`}>
        <Icon src={default_mail}></Icon>
        <Label
          size={typography.size.s3}
          font={typography.type.primary}
          color="#000000"
        >Default email app</Label>
      </Item>
      <Copy>
        <ButtonCopy onClick={() => { navigator.clipboard.writeText(email) }}>
          <IconButton src={link_icon}></IconButton>
          <Text
            weight={typography.weight.regular}
            font={typography.type.primary}
            color={color.primary.white}
          >Copy link</Text>
        </ButtonCopy>
        <Mail
          href={`mailto:${email}`}>{email}</Mail>
      </Copy>
    </WrapperDetail>
  )
}

const WrapperDetail = styled.div`
  width: 500px;
  position: absolute;
  z-index: 100;
  background-color: white;
  padding: 20px;
  @media (max-width: 649px) {
    width: 80%;
  }
`
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`
const Title = styled(Text)`

`;
const Item = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 14px;
  border: 1px solid #D2D9E2;
  margin: 6px 0;
  cursor: pointer;
`
const Icon = styled.img`
  margin-right: 20px;
`
const CloseIcon = styled.img`
  cursor: pointer;
`
const Label = styled(Text)`
  cursor: pointer;
  span {
    cursor: pointer;
  }
`
const Copy = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #D2D9E2;
  margin: 6px 0;
`
const ButtonCopy = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  background-color: ${color.primary.blue};
  margin-right:20px;
  cursor: pointer;
  span {
    cursor: pointer;
  }
`

const IconButton = styled.img`
  margin-right: 10px;
`
const Mail = styled.a`
  color:#3DAE73;
  font-family: ${typography.type.primary};
  font-size: ${typography.size.s3};
  line-height: 26px;
  text-decoration: underline;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
`